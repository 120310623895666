import { Redirect } from 'react-router-dom';

import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { NewsFormPage, NewsListPage } from 'app/pages/NewsPage';
import { CentreFormPage, CentreListPage } from 'app/pages/CentrePage';
import {
  CentreCategorySortListPage,
  NewsCategorySortListPage,
  UsefulInfoCategorySortListPage,
} from 'app/pages/SortListPage';
import { UsefulInfoListPage } from 'app/pages/UsefulInfoPage/UsefulInfoListPage';
import { UsefulInfoFormPage } from 'app/pages/UsefulInfoPage/UsefulInfoFormPage';
import { QuizListPage } from 'app/pages/QuizPage/QuizListPage';
import { QuizDetailPage } from 'app/pages/QuizPage/QuizDetailPage';
import {
  ConsultingRoomListPage,
  ConsultationRecordListPage,
} from 'app/pages/ConsultingPage';
import PrivacyPolicyPage from 'app/pages/PrivacyPolicyPage';
import { LogListPage } from 'app/pages/LogPage';
import {
  NotificationFormPage,
  NotificationListPage,
} from 'app/pages/NotificationPage';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
  permissions?: string[];
}
const userRoutes: Route[] = [
  // admin
  { path: '/admins', component: AdminListPage, permissions: ['超級管理員'] },
  {
    path: '/admins/new',
    component: AdminFormPage,
    exact: true,
    permissions: ['超級管理員'],
  },
  {
    path: '/admins/edit/:adminId',
    component: AdminFormPage,
    exact: true,
    permissions: ['超級管理員'],
  },
  {
    path: '/admins/edit/:adminId/:password',
    component: AdminFormPage,
    exact: true,
    permissions: ['超級管理員'],
  },
  {
    path: '/centres',
    component: CentreListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/centres/new',
    component: CentreFormPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/centres/edit/:centreId',
    component: CentreFormPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/sorting/newsCategories',
    component: NewsCategorySortListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/sorting/centreCategories',
    component: CentreCategorySortListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/sorting/usefulInfoCategories',
    component: UsefulInfoCategorySortListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/news',
    component: NewsListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/news/new',
    component: NewsFormPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/news/edit/:newsId',
    component: NewsFormPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/usefulInfo',
    component: UsefulInfoListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/usefulInfo/new',
    component: UsefulInfoFormPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/usefulInfo/edit/:usefulInfoId',
    component: UsefulInfoFormPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/quizes',
    component: QuizListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/quizes/:page/detail/:quizId',
    component: QuizDetailPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/consulting',
    component: ConsultingRoomListPage,
    permissions: ['超級管理員', '輔導員'],
  },
  {
    path: '/chat-room-status-log',
    component: LogListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/consultingRecord',
    component: ConsultationRecordListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/notifications',
    component: NotificationListPage,
    permissions: ['超級管理員'],
  },
  {
    path: '/notifications/new',
    component: NotificationFormPage,
    permissions: ['超級管理員'],
  },
  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/privacyPolicy', component: PrivacyPolicyPage },
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
