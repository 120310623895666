import { Admin } from './Admin';
import { TimeStamp } from './Common';

export interface User extends TimeStamp {
  age?: string;
  currentMood?: string;
  education?: string;
  gender?: '男' | '女';
  livingRegion: string;
  name?: string;
  uid: string;
  userId: number;
  adminId?: number;
  displayName?: string;
  status: string;
  remarks: string;
  team: string | null;
}

export const STATUS_OPTIONS = [
  {
    label: '開放',
    value: 'Opened',
  },
  {
    label: '關閉',
    value: 'Closed',
  },
];

export interface ChatRoomStatus extends TimeStamp {
  chatConfigId: number;
  status: 'Started' | 'Suspended';
}

export interface UpdateSurveyParams {
  typeOfChat: string;
  weekOfDay: string;
  consultationSession: string;
  isFirstTime: boolean | null;
  consultingPerson: string;
  othersPersonEffect: string;
  isHimselfUnderMedical: boolean | null;
  previousMedicalDate: string;
  medicalNature: string;
  diagnosisResult: string;
  underMedicalSocialWorker: string;
  underMedicalNurse: string;
  underMedicalSocialService: string;
  underOtherSocialService: string;
  symptomFrequency: string;
  symptomDuration: string;
  symptomEffect: string;
  symptomCausePersonalFactor: string;
  symptomCauseRelationFactor: string;
  symptomCauseEnvironmentFactor: string;
  symptomCauseOtherFactor: string;
  mainIssueCategory: string;
  consultantService: string;
  providedRefererService: string;
  userName: string;
  userEmploymentStatus: string;
  consultationDuration: string;
  remarks: string;
  Symptoms: {
    illness: string;
    symptom: string;
  }[];
}

export interface SurveyResponse extends TimeStamp, UpdateSurveyParams {
  adminId: number;
  surveyId: number;
  userId: number;
}

export interface ChatRoomStatusLog extends TimeStamp {
  chatConfigId: number;
  status: ChatRoomStatusType;
  adminId: number | null;
  Admin: Admin | null;
}

export type ChatRoomStatusType = 'Started' | 'Suspended';
