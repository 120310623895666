import {
  Chatroom,
  FirestoreChatroomData,
  FirestoreUserData,
  SurveyResponse,
  UpdateSurveyParams,
} from 'app/models';
import { SurveyFormItem } from 'app/pages/ConsultingPage/forms';
import moment from 'moment';
import 'moment/locale/zh-hk';
import {
  TYPE_OF_CHAT,
  MEDICAL_RESULT,
  OTHER_SOCIAL_SERVICE,
  OTHER_SYSMTOMS,
  SYMPTOM_CAUSE_PERSONAL_FACTOR,
  SYMPTOM_CAUSE_RELATION_FACTOR,
  SYMPTOM_CAUSE_ENVIRONMENT_FACTOR,
  MAIN_ISSUE_CATEGORY,
  CONSULTANT_SERVICE,
  PROVIDED_REFERER_SERVICE,
  USER_EMPLOYMENT_STATUS,
} from 'app/pages/ConsultingPage/forms';
import _ from 'lodash';

export const formatChatRoom = (
  roomid: string,
  room: FirestoreChatroomData,
  user: FirestoreUserData,
): Chatroom => {
  const chatroom: Chatroom = {
    id: roomid,
    name: user.name || '',
    createdAt: moment(room.createdAt.toDate()).format('YYYY-MM-DD HH:mm'),
    updatedAt: moment(room.updatedAt.toDate()).format('YYYY-MM-DD HH:mm'),
    userId: user.userId,
    uid: user.uid,
    age: user.age || '',
    currentMood: user.currentMood || '',
    education: user.education || '',
    gender: user.gender || '',
    livingRegion: user.livingRegion || '',
    isHandled: room.lastMessage?.readBy?.admin ? true : false,
    status: room.status,
    lastMessage: room.lastMessage
      ? room.lastMessage.text
        ? room.lastMessage.text
        : room.lastMessage.image
        ? '圖片'
        : room.lastMessage.systemText
        ? room.lastMessage.systemText
        : room.lastMessage.system === 'chatInit'
        ? '閣下正在輪候中，輔導員將盡快與您通話，請耐心等侯！'
        : ''
      : '',
    sender: room.lastMessage?.sentBy || '',
    team: room.team,
    typing:
      room.typingUsers &&
      room.typingUsers.length &&
      room.typingUsers.includes(user.uid)
        ? true
        : false,
    survey: room.isFilledSurvey ? true : false,
  };
  return chatroom;
};

export const formatChatRoomUserInfo = (user: Chatroom | null): string => {
  if (user) {
    const { age, currentMood, education, livingRegion } = user;
    return `${age}歲 | ${currentMood} | ${education} | ${livingRegion}`;
  }
  return '';
};

export const formatSurveyData = (
  values: SurveyFormItem,
): UpdateSurveyParams => {
  const {
    typeOfChat,
    typeOfChatOther,
    weekOfDay,
    consultationSession,
    isFirstTime,
    consultingPerson,
    othersPersonEffect,
    isHimselfUnderMedical,
    previousMedicalDate,
    medicalNature,
    diagnosisResult,
    diagnosisResultOther,
    underMedicalSocialWorker,
    underMedicalNurse,
    underMedicalSocialService,
    underOtherSocialService,
    underOtherSocialServiceOther,
    schizophrenia,
    depression,
    mania,
    anxiety,
    otherSystoms,
    otherSystomsOther,
    symptomFrequency,
    symptomDuration,
    symptomEffect,
    symptomCausePersonalFactor,
    symptomCausePersonalFactorOther,
    symptomCauseRelationFactor,
    symptomCauseRelationFactorOther,
    symptomCauseEnvironmentFactor,
    symptomCauseEnvironmentFactorOther,
    symptomCauseOtherFactor,
    mainIssueCategory,
    mainIssueCategoryOther,
    consultantService,
    consultantServiceOther,
    providedRefererService,
    providedRefererServiceOther,
    userName,
    userEmploymentStatus,
    userEmploymentStatusOther,
    consultationDuration,
    remarks,
  } = values;
  let params: UpdateSurveyParams = {
    typeOfChat: typeOfChat === '其他' ? typeOfChatOther : typeOfChat,
    weekOfDay,
    consultationSession,
    isFirstTime: isFirstTime ? (isFirstTime === 'true' ? true : false) : null,
    consultingPerson,
    othersPersonEffect,
    isHimselfUnderMedical: isHimselfUnderMedical
      ? isHimselfUnderMedical === 'true'
        ? true
        : false
      : null,
    previousMedicalDate,
    medicalNature,
    diagnosisResult:
      diagnosisResult === '其他' ? diagnosisResultOther : diagnosisResult,
    underMedicalSocialWorker,
    underMedicalNurse,
    underMedicalSocialService,
    underOtherSocialService:
      underOtherSocialService === '其他'
        ? underOtherSocialServiceOther
        : underOtherSocialService,
    Symptoms: [],
    symptomFrequency,
    symptomDuration,
    symptomEffect,
    symptomCausePersonalFactor:
      symptomCausePersonalFactor === '其他'
        ? symptomCausePersonalFactorOther
        : symptomCausePersonalFactor,
    symptomCauseRelationFactor:
      symptomCauseRelationFactor === '其他'
        ? symptomCauseRelationFactorOther
        : symptomCauseRelationFactor,
    symptomCauseEnvironmentFactor:
      symptomCauseEnvironmentFactor === '其他'
        ? symptomCauseEnvironmentFactorOther
        : symptomCauseEnvironmentFactor,
    symptomCauseOtherFactor,
    mainIssueCategory:
      mainIssueCategory === '其他問題徵狀'
        ? mainIssueCategoryOther
        : mainIssueCategory,
    consultantService:
      consultantService === '其他' ? consultantServiceOther : consultantService,
    providedRefererService:
      providedRefererService === '有'
        ? providedRefererServiceOther
        : providedRefererService,
    userName,
    userEmploymentStatus:
      userEmploymentStatus === '其他'
        ? userEmploymentStatusOther
        : userEmploymentStatus,
    consultationDuration,
    remarks,
  };

  if (consultingPerson === '本身的問題') {
    params.othersPersonEffect = '';
  }

  if (isHimselfUnderMedical === 'false') {
    params.previousMedicalDate = '';
    params.medicalNature = '';
    params.diagnosisResult = '';
    params.underMedicalSocialWorker = '';
    params.underMedicalNurse = '';
    params.underMedicalSocialService = '';
    params.underOtherSocialService = '';
  }

  if (schizophrenia && schizophrenia.length) {
    params.Symptoms = [
      ...params.Symptoms,
      ...schizophrenia.map(item => ({
        illness: '思覺失調',
        symptom: item,
      })),
    ];
  }

  if (depression && depression.length) {
    params.Symptoms = [
      ...params.Symptoms,
      ...depression.map(item => ({
        illness: '抑鬱',
        symptom: item,
      })),
    ];
  }

  if (mania && mania.length) {
    params.Symptoms = [
      ...params.Symptoms,
      ...mania.map(item => ({
        illness: '躁狂',
        symptom: item,
      })),
    ];
  }

  if (anxiety && anxiety.length) {
    params.Symptoms = [
      ...params.Symptoms,
      ...anxiety.map(item => ({
        illness: '焦慮',
        symptom: item,
      })),
    ];
  }

  if (otherSystoms && otherSystoms.length) {
    params.Symptoms = [
      ...params.Symptoms,
      ...otherSystoms.map(item => {
        if (item === '其他') {
          return {
            illness: '其他',
            symptom: otherSystomsOther,
          };
        } else {
          return {
            illness: '其他',
            symptom: item,
          };
        }
      }),
    ];
  }

  return params;
};

export const formatSurveyToForm = (
  surveyRes: SurveyResponse,
): SurveyFormItem => {
  const {
    typeOfChat,
    weekOfDay,
    consultationSession,
    isFirstTime,
    consultingPerson,
    othersPersonEffect,
    isHimselfUnderMedical,
    previousMedicalDate,
    medicalNature,
    diagnosisResult,
    underMedicalSocialWorker,
    underMedicalNurse,
    underMedicalSocialService,
    underOtherSocialService,
    Symptoms,
    symptomFrequency,
    symptomDuration,
    symptomEffect,
    symptomCausePersonalFactor,
    symptomCauseRelationFactor,
    symptomCauseEnvironmentFactor,
    symptomCauseOtherFactor,
    mainIssueCategory,
    consultantService,
    providedRefererService,
    userName,
    userEmploymentStatus,
    consultationDuration,
    remarks,
  } = surveyRes;
  const form: SurveyFormItem = {
    typeOfChat: TYPE_OF_CHAT.includes(typeOfChat) ? typeOfChat : '其他',
    typeOfChatOther: TYPE_OF_CHAT.includes(typeOfChat) ? '' : typeOfChat,
    weekOfDay,
    consultationSession,
    isFirstTime: typeof isFirstTime === 'boolean' ? isFirstTime.toString() : '',
    consultingPerson,
    othersPersonEffect,
    isHimselfUnderMedical:
      typeof isHimselfUnderMedical === 'boolean'
        ? isHimselfUnderMedical.toString()
        : '',
    previousMedicalDate,
    medicalNature,
    diagnosisResult: !diagnosisResult
      ? ''
      : MEDICAL_RESULT.includes(diagnosisResult)
      ? diagnosisResult
      : '其他',
    diagnosisResultOther: !diagnosisResult
      ? ''
      : MEDICAL_RESULT.includes(diagnosisResult)
      ? ''
      : diagnosisResult,
    underMedicalSocialWorker,
    underMedicalNurse,
    underMedicalSocialService,
    underOtherSocialService: !underOtherSocialService
      ? ''
      : OTHER_SOCIAL_SERVICE.includes(underOtherSocialService)
      ? underOtherSocialService
      : '其他',
    underOtherSocialServiceOther: !underOtherSocialService
      ? ''
      : OTHER_SOCIAL_SERVICE.includes(underOtherSocialService)
      ? ''
      : underOtherSocialService,
    schizophrenia: Symptoms.filter(
      symtoms => symtoms.illness === '思覺失調',
    ).map(item => item.symptom),
    depression: Symptoms.filter(symtoms => symtoms.illness === '抑鬱').map(
      item => item.symptom,
    ),
    mania: Symptoms.filter(symtoms => symtoms.illness === '躁狂').map(
      item => item.symptom,
    ),
    anxiety: Symptoms.filter(symtoms => symtoms.illness === '焦慮').map(
      item => item.symptom,
    ),
    otherSystoms: Symptoms.filter(
      symtoms => symtoms.illness === '其他',
    ).map(item =>
      OTHER_SYSMTOMS.includes(item.symptom) ? item.symptom : '其他',
    ),
    otherSystomsOther: '',
    symptomFrequency,
    symptomDuration,
    symptomEffect,
    symptomCausePersonalFactor: !symptomCausePersonalFactor
      ? ''
      : SYMPTOM_CAUSE_PERSONAL_FACTOR.includes(symptomCausePersonalFactor)
      ? symptomCausePersonalFactor
      : '其他',
    symptomCausePersonalFactorOther: !symptomCausePersonalFactor
      ? ''
      : SYMPTOM_CAUSE_PERSONAL_FACTOR.includes(symptomCausePersonalFactor)
      ? ''
      : symptomCausePersonalFactor,
    symptomCauseRelationFactor: !symptomCauseRelationFactor
      ? ''
      : SYMPTOM_CAUSE_RELATION_FACTOR.includes(symptomCauseRelationFactor)
      ? symptomCauseRelationFactor
      : '其他',
    symptomCauseRelationFactorOther: !symptomCauseRelationFactor
      ? ''
      : SYMPTOM_CAUSE_RELATION_FACTOR.includes(symptomCauseRelationFactor)
      ? ''
      : symptomCauseRelationFactor,
    symptomCauseEnvironmentFactor: !symptomCauseEnvironmentFactor
      ? ''
      : SYMPTOM_CAUSE_ENVIRONMENT_FACTOR.includes(symptomCauseEnvironmentFactor)
      ? symptomCauseEnvironmentFactor
      : '其他',
    symptomCauseEnvironmentFactorOther: !symptomCauseEnvironmentFactor
      ? ''
      : SYMPTOM_CAUSE_ENVIRONMENT_FACTOR.includes(symptomCauseEnvironmentFactor)
      ? ''
      : symptomCauseEnvironmentFactor,
    symptomCauseOtherFactor,
    mainIssueCategory: !mainIssueCategory
      ? ''
      : MAIN_ISSUE_CATEGORY.includes(mainIssueCategory)
      ? mainIssueCategory
      : '其他問題徵狀',
    mainIssueCategoryOther: !mainIssueCategory
      ? ''
      : MAIN_ISSUE_CATEGORY.includes(mainIssueCategory)
      ? ''
      : mainIssueCategory,
    consultantService: !consultantService
      ? ''
      : CONSULTANT_SERVICE.includes(consultantService)
      ? consultantService
      : '其他',
    consultantServiceOther: !consultantService
      ? ''
      : CONSULTANT_SERVICE.includes(consultantService)
      ? ''
      : consultantService,
    providedRefererService: !providedRefererService
      ? ''
      : PROVIDED_REFERER_SERVICE.includes(providedRefererService)
      ? providedRefererService
      : '其他',
    providedRefererServiceOther: !providedRefererService
      ? ''
      : PROVIDED_REFERER_SERVICE.includes(providedRefererService)
      ? ''
      : providedRefererService,
    userName,
    userEmploymentStatus: !userEmploymentStatus
      ? ''
      : USER_EMPLOYMENT_STATUS.includes(userEmploymentStatus)
      ? userEmploymentStatus
      : '其他',
    userEmploymentStatusOther: !userEmploymentStatus
      ? ''
      : USER_EMPLOYMENT_STATUS.includes(userEmploymentStatus)
      ? ''
      : userEmploymentStatus,
    consultationDuration,
    remarks,
  };
  const index = _.findIndex(
    Symptoms,
    symptom =>
      symptom.illness === '其他' && !OTHER_SYSMTOMS.includes(symptom.symptom),
  );
  if (index > -1) {
    form.otherSystomsOther = Symptoms[index].symptom;
  }
  return form;
};
