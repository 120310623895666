import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Table } from 'reactstrap';

interface Props {
  columns: any[];
  data: any[];
  keyField: string;
  onClick?: (id: string) => void;
}

function CustomTable({ columns, data, keyField, onClick }: Props) {
  const [selectedField, setSelectedField] = useState<string>('');
  const [orderType, setOrderType] = useState<'asc' | 'desc' | undefined>();
  const history = useHistory();
  const location = useLocation();

  const columnOnClick = (field: string) => {
    if (field === selectedField) {
      setOrderType(orderType === 'desc' ? 'asc' : 'desc');
    } else {
      setOrderType('asc');
    }
    setSelectedField(field);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (orderType) {
      params.set(orderType, selectedField);
      params.delete(orderType === 'asc' ? 'desc' : 'asc');
      history.push({ search: params.toString() });
    }
  }, [selectedField, orderType, location.search, history]);

  return (
    <Table className="table align-middle table-nowrap table-hover">
      <thead className="table-light">
        <tr>
          {columns.map((column, index) => {
            const canSort =
              column.text !== '' &&
              column.dataField !== 'img' &&
              column.dataField !== 'menu';
            return (
              <th
                key={`${column.text}_${index}`}
                onClick={() => {
                  if (canSort) columnOnClick(column.dataField);
                }}
                className={`${
                  canSort
                    ? selectedField === column.dataField
                      ? 'text-primary'
                      : ''
                    : ''
                } `}
                role={canSort ? 'button' : ''}
                style={{ userSelect: 'none' }}
              >
                {column.text}
                {canSort ? (
                  <>
                    {selectedField === column.dataField ? (
                      <>
                        {orderType === 'asc' ? (
                          <i
                            className="bx bxs-up-arrow position-relative"
                            style={{ fontSize: 8, bottom: 5, left: 3 }}
                          />
                        ) : (
                          <i
                            className="bx bxs-down-arrow position-relative"
                            style={{ fontSize: 8, bottom: 5, left: 3 }}
                          />
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}
              </th>
            );
          })}
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {data.map((item, index) => {
            return (
              <tr
                key={`${item[keyField]}_${index}`}
                onClick={() => {
                  if (onClick) {
                    onClick(item[keyField]);
                  }
                }}
              >
                {columns.map((column, subIndex) => {
                  if (column.formatter) {
                    return (
                      <td key={`${item[keyField]}_${index}_${subIndex}`}>
                        {column.formatter(item)}
                      </td>
                    );
                  } else {
                    return (
                      <td key={`${item[keyField]}_${index}_${subIndex}`}>
                        {item[column.dataField]}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td
              colSpan={columns.length}
              className="text-center border-0 text-size-16 fw-bold"
            >
              沒有資料
            </td>
          </tr>
        </tbody>
      )}
    </Table>
  );
}

export default CustomTable;
