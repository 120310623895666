import { TimeStamp } from './Common';

export interface Quiz extends TimeStamp {
  quizId: number;
  type: string;
  overallScore: number;
  rawAnswers: number[];
}

export interface QuizQuestion {
  question: string;
  options: string[];
  description?: string[];
}

export enum QuizTypeColor {
  壓力測試 = 'info',
  抑鬱測試 = 'danger',
  焦慮測試 = 'warning',
}

export const QUIZ_TYPE_OPTIONS = [
  { value: '壓力測試', label: '壓力測試', color: QuizTypeColor.壓力測試 },
  { value: '抑鬱測試', label: '抑鬱測試', color: QuizTypeColor.抑鬱測試 },
  { value: '焦慮測試', label: '焦慮測試', color: QuizTypeColor.焦慮測試 },
];

export const QUIZ_QUESTIONS: Record<string, QuizQuestion[]> = {
  壓力測試: [
    {
      question: '對突然發生的事感到心煩意亂',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
      description: [
        '以下的壓力測試採用PSS-10作為評估工具，PSS是Perceived Stress Scale的簡稱，總共有10題。是根據個人在生活事件上對壓力的感知，而評估一個人的壓力程度。',
        '請回想在過去1個月，你有多經常感受到以下情況？',
      ],
    },
    {
      question: '無法控制自己生活中重要的事情',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
    {
      question: '緊張不安和壓力',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
    {
      question: '對於處理私人問題的能力感到有信心',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
    {
      question: '事情順心如意',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
    {
      question: '發現自己無法處理各樣必須做的事',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
    {
      question: ' 有辦法控制生活中惱人的事情',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
    {
      question: '覺得自己是駕馭事情的主人',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
    {
      question: '事情的發生超出自己所能控制而生氣',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
    {
      question: '困難的事堆積如山，自己無法克服',
      options: ['從不', '多數不會', '有時', '常常', '總會'],
    },
  ],
  抑鬱測試: [
    {
      question: '做事缺乏興趣或樂趣',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
      description: [
        '以下的抑鬱測試採用PHQ-9 作為評估工具，是一份測試抑鬱情緒的問卷。',
        'PHQ 是The Patient Health Questionnaire 的簡稱，源自美國精神醫學會《精神病診斷手冊》內的抑鬱症的診斷症狀',
        '請回想在過去14 天，您多經常受以下問題困擾？',
      ],
    },
    {
      question: '感到低落、沮喪、或絕望',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '難以入睡、容易驚醒、或過度睡眠',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '感到疲倦或精力不足',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '食慾不振或過度飲食',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '覺得自己很差勁、是個失敗者，令自己和家人失望',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '難以集中精神 (例如在閱報或看電視時)',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '連別人也察覺到您的動作或說話緩慢；心緒不寧或坐立不安',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '曾有自殺念頭',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question:
        '如果您有上述列出的問題，這些問題對您的工作、處理家庭事務及人際相處等造成甚麼程度的困難？',
      options: ['沒有困難', '一些困難', '十分困難', '極之困難'],
    },
  ],
  焦慮測試: [
    {
      question: '感到緊張、不安或煩躁',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
      description: [
        '以下的焦慮測試採用GAD-7作為評估工具，是一份測試焦慮程度的問卷。GAD是Generalized Anxiety Disorder的簡稱，問卷總共有7題。',
        '請回想在過去14天，你多經常受以下問題困擾？',
      ],
    },
    {
      question: '無法停止或控制憂慮',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '過份憂慮不同的事情',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '難以放鬆',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '心緒不寧以至坐立不安',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '容易心煩或易怒',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
    {
      question: '感到害怕，就像要發生可怕的事情',
      options: ['完全沒有', '幾天', '7天以上', '幾乎每天'],
    },
  ],
};
