import { APP_NAME } from 'app/config';
import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';

const PrivacyPolicyPage = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>私隱權條款 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <h4>香港心理衞生會</h4>
                            <h4>私隱政策-網上程式使用</h4>
                          </div>
                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <p>
                                香港心理衞生會（包括其僱員及代理人）（下稱「本會」）會確保所有透過「輔負得正」遞交的個人資料，均按照《個人資料（私隱）條例》的有關條文處理。
                              </p>

                              <p className="mb-4">
                                本會會記錄閣下使用「輔負得正」的相關數據，但並不會收集任何足以辨識使用者身分的資料。所收集的數據只會用於製作使用報告及調查電腦系統問題，以助改善「輔負得正」服務。
                              </p>

                              <p className="mb-4">
                                本會可能會於不同期間及為個別原因，邀請閣下自願提供個人資料。此等資料可能包括姓名或電話。本會邀請閣下提供資料時，會列明收集資料的目的和用途，並會告知閣下如何要求查閱或改正所提供的個人資料。
                              </p>

                              <p className="mb-4">
                                為了提升用戶的體驗及減少閣下啟動程式所需的時間，「輔負得正」會儲存已下載的資料於閣下的流動裝置上。這需要閣下在流動裝置上授權儲存裝置的讀取和修改訪問權限。
                              </p>

                              <p className="mb-4">
                                「 輔負得正」支援透過 APNS / GCM/
                                PUSHMAIL接收政府資訊，閣下需要允許相關權限以使用該等服務。
                              </p>

                              <p className="mb-4">
                                「輔負得正」支援透過第三者服務供應商的「分享」功能。當你使用「輔負得正」的「分享」功能，請參閱個別服務的私隱政策。
                              </p>

                              <p className="mt-4 text-muted font-size-12">
                                此版權告示的英文及中文版本
                              </p>
                              <p className="mb-4 text-muted font-size-12">
                                此中文版權告示為英文版本譯本，如中、英文兩個版本有任何抵觸或不相符之處，應以英文版本為準。
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div className="text-center">
                            <h4>THE MENTAL HEALTH ASSOCIATION OF HONG KONG</h4>
                            <h4>Privacy Policy- Apps Usage</h4>
                          </div>
                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <p className="mb-4">
                                The Mental Health Association of Hong Kong
                                (including its servants and agents) ("the
                                MHAHK") takes measures to ensure that all
                                personal data submitted through the MHAHK
                                Notifications is handled in accordance with the
                                relevant provisions of the Personal Data
                                (Privacy) Ordinance.
                              </p>

                              <p className="mb-4">
                                The MHAHK will record information related to
                                your usage of MHAHK Notifications, but without
                                collecting any personal identifiable
                                information. Such general information are
                                collected to compile usage reports and diagnose
                                problems with, or concerning, computer systems
                                to help improving MHAHK Notifications.
                              </p>

                              <p className="mb-4">
                                For different purposes and at different times
                                you may be invited to provide personal data to
                                the MHAHK through this application on a
                                voluntary basis. Personal information may
                                include your name or e-mail address. The MHAHK
                                will specify the collection purpose and intended
                                usage of your data when it invites you to
                                provide such information and advise you how you
                                may request access to or correction of the
                                personal data provided.
                              </p>
                              <p className="mb-4">
                                With a view to improving user experience and
                                reduce the startup time of the app, MHAHK
                                Notifications would store downloaded data on
                                your mobile device. This feature requires your
                                authorization on read and write access to the
                                storage of your mobile device.
                              </p>
                              <p className="mb-4">
                                The MHAHK Notifications supports the reception
                                of MHAHK information through APNS / GCM /PUSH
                                MAIL services. You are required to enable
                                related authorization to access such services.
                              </p>
                              <p className="mb-4">
                                MHAHK Notifications supports sharing of
                                notifications via third party service providers
                                ("Share" function). If you use the "Share"
                                function of MHAHK Notifications, please refer to
                                the privacy policy of corresponding service
                                providers.
                              </p>
                              <p className="mt-4 text-muted font-size-12">
                                English and Chinese Versions of this notice
                              </p>
                              <p className="mb-4 text-muted font-size-12">
                                This Notice has been translated into Chinese. If
                                there is any inconsistency or ambiguity between
                                the English version and the Chinese version, the
                                English version shall prevail.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;
