import { Filter, ListResult, Quiz } from 'app/models';
import Axios from '.';

export interface GetQuizListParams extends Filter {
  type?: string;
}

export const getQuizList = async (params: GetQuizListParams) => {
  const { data } = await Axios.get<ListResult<Quiz>>('/quiz', {
    params,
  });
  return data;
};
