import React from 'react';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from 'store';
import { useAdminPermission } from 'store/Permission';

interface Props {
  component: any;
  isAuthProtected: boolean;
  layout?: any;
  exact?: boolean;
  path: string;
  permissions?: string[];
}

const ProtectedRoute = ({
  component: Component,
  isAuthProtected,
  layout: Layout,
  exact,
  path,
  permissions,
  ...rest
}: Props) => {
  const { Auth } = useSelector((rootState: RootState) => rootState.auth);
  const { hasPermission } = useAdminPermission();
  return (
    <Route
      {...rest}
      path={path}
      exact
      render={props => {
        if (isAuthProtected && !Auth) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
        // permission check here
        if (permissions && hasPermission(permissions)) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        } else if (permissions && !hasPermission(permissions)) {
          return (
            <Redirect
              to={{ pathname: '/notfound', state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default ProtectedRoute;
