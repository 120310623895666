import { Quiz, QuizTypeColor } from 'app/models/';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import './quiz.scss';

const QuizListColumns = (page: number) => [
  {
    text: '編號',
    dataField: 'quizId',
    formatter: (quiz: Quiz) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">#{quiz.quizId}</div>
        </h5>
      </>
    ),
  },
  {
    text: '測驗類型',
    dataField: 'type',
    sort: false,
    formatter: (quiz: Quiz) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Badge
            pill
            className={`badge-soft-${
              QuizTypeColor[quiz.type]
            } ms-1 p-2 font-size-12`}
          >
            {quiz.type ? quiz.type : '-'}
          </Badge>
        </h5>
      </>
    ),
  },
  {
    text: '總分',
    dataField: 'overallScore',
    sort: false,
    formatter: (quiz: Quiz) => (
      <>
        <h5 className="font-size-14 mb-1">
          {quiz.overallScore ? quiz.overallScore : '0'}
        </h5>
      </>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    sort: false,
    formatter: (quiz: Quiz) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(quiz.createdAt).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (quiz: Quiz) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item ">
          <Link
            to={`/quizes/${page}/detail/${quiz.quizId}`}
            id={'detail' + quiz.quizId}
          >
            <i className="bx bx-detail" />
            <UncontrolledTooltip
              placement="top"
              target={'detail' + quiz.quizId}
            >
              編輯資訊
            </UncontrolledTooltip>
          </Link>
        </li>
      </ul>
    ),
  },
];

export default QuizListColumns;
