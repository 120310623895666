import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Row, Col, Card, CardBody, Container, CardTitle } from 'reactstrap';

import { RadioCheckboxField } from 'app/components/Form';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { changePreloader } from 'store/Layout';
import { APP_NAME } from 'app/config';
import { getQuizList, GetQuizListParams } from 'app/services/QuizService';
import { Quiz, QuizQuestion, QUIZ_QUESTIONS } from 'app/models';

export interface QuizDetailPageQuery {
  quizId: string;
  page: string;
}

export function QuizDetailPage() {
  const [quiz, setQuiz] = useState<Quiz>();
  const [currentQuizContent, setCurrentQuizContent] = useState<QuizQuestion[]>(
    QUIZ_QUESTIONS['壓力測試'],
  );
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const params = useParams<QuizDetailPageQuery>();

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { quizId, page } = params;
    try {
      if (quizId) {
        const param: GetQuizListParams = {
          limit: 20,
          offset: (parseInt(page) - 1) * 20,
        };
        const quizRes = await getQuizList(param);
        let currentQuiz = quizRes.rows.find(
          quiz => quiz.quizId === parseInt(quizId),
        );

        if (currentQuiz?.type === '壓力測試') {
          const formattedArray = currentQuiz.rawAnswers.map((answer, index) => {
            if (
              index + 1 === 4 ||
              index + 1 === 5 ||
              index + 1 === 7 ||
              index + 1 === 8
            ) {
              return [4, 3, 2, 1, 0][answer];
            } else {
              return answer;
            }
          });
          currentQuiz = { ...currentQuiz, rawAnswers: formattedArray };
        } else if (currentQuiz?.type === '抑鬱測試') {
          currentQuiz = {
            ...currentQuiz,
            rawAnswers: currentQuiz.rawAnswers,
          };
        }
        setQuiz(currentQuiz);
        setCurrentQuizContent(
          QUIZ_QUESTIONS[currentQuiz?.type ? currentQuiz?.type : '壓力測試'],
        );
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.error(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const TITLE = `測驗記錄表格`;
  const BreadcrumbsOptions = [
    { title: '測驗記錄表格列表', path: '/quizes' },
    { title: TITLE, path: '#' },
  ];
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>測驗記錄列表 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title={TITLE} breadcrumbItems={BreadcrumbsOptions} />

          <div className="mt-4">
            {!isPreloader ? (
              <Card style={{ flex: 1 }}>
                <CardBody>
                  <Row className="mt-4">
                    <Col className="d-flex flex-row justify-content-between">
                      <CardTitle>{quiz?.type}</CardTitle>
                      <CardTitle>測驗記錄編號: #{quiz?.quizId}</CardTitle>
                    </Col>
                  </Row>
                  {currentQuizContent.map((question, index) => (
                    <Row key={index} className="mt-4">
                      <Col lg="12">
                        {index + 1}. {question.question} ?
                      </Col>
                      <Col lg="12" className="mt-3">
                        {question.options.map((option, subIndex) => (
                          <RadioCheckboxField
                            key={subIndex}
                            label={option}
                            placeholder=""
                            horizontal
                            type="radio"
                            className="me-5 ms-3"
                            check={quiz?.rawAnswers[index] === subIndex}
                            disabled
                          />
                        ))}
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col lg="12" className="mt-4 mb-4 ">
                      <h4 className="text-end">總分: {quiz?.overallScore}</h4>
                      {/* <h4 className="text-end">answer: {quiz?.rawAnswers}</h4> */}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : null}
          </div>
        </Container>
      </div>
    </>
  );
}
