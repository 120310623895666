import * as Yup from 'yup';
import { OptionItems } from 'app/models';

export const TYPE_OF_CHAT: string[] = [
  '戲弄',
  '聊天',
  '服務查詢',
  '該節重覆求助',
  '長期求助者',
  '已處理',
  '其他',
];

// A
export const CONSULTING_NATURE: OptionItems[] = [
  { label: '首次使用', value: 'true' },
  { label: '非首次使用', value: 'false' },
];

// B
export const ISSUE_TYPE: OptionItems[] = [
  { label: '本身的問題(直接續答「問題D」)', value: '本身的問題' },
  { label: '家人／朋友的問題', value: '家人／朋友的問題' },
];

// C
export const OTHER_PERSON_EFFECT: string[] = [
  '嚴重影響',
  '頗大影響',
  '有一定程度影響',
  '少許影響',
  '沒有影響',
];

// D
export const IS_UNDER_MEDICAL: OptionItems[] = [
  { label: '有(續答下列各問題。)', value: 'true' },
  { label: '沒有(續答「E部」)', value: 'false' },
];

export const LAST_MEDICAL_FOLLOW_UP_TIME: string[] = [
  '兩星期前',
  '四星期前',
  '三個月前',
  '半年前',
  '一年前',
  '超過一年',
  '不清楚',
];

export const MEDICAL_SERVICE_NATURE: string[] = [
  '門診',
  '住院',
  '日間醫院',
  '私家精神科醫生／臨床心理學家',
];

export const MEDICAL_RESULT: string[] = [
  '焦慮症',
  '精神分裂症',
  '抑鬱症',
  '躁狂症',
  '躁狂抑鬱症',
  '性格異常',
  '認知障礙',
  '自閉症',
  '過度活躍/專注力失調/學習障礙',
  '智力障礙',
  '不知道',
  '其他',
];

export const YES_NO_DK_OPTIONS: string[] = ['有', '沒有', '不知道'];

export const OTHER_SOCIAL_SERVICE: string[] = [
  '私家輔導員',
  '家庭服務',
  '普通科醫生',
  '支援小組',
  '其他',
  '沒有',
  '不知道',
];

// E
export const SCHIZOPHRENIA_SYMPTOMS = ['妄想', '幻覺', '錯亂言語', '錯亂行為'];

export const DEPRESSION_SYMPTOMS = [
  '情緒低落',
  '食慾問題',
  '睡眠問題',
  '終日疲累',
  '對生活缺乏興趣',
  '缺乏自信',
  '自殺傾向',
  '過份自責',
];

export const MANIA_SYMPTOMS = ['極度興奮', '急躁', '過份自信', '過份活躍'];

export const ANXIETY_SYMPTOMS = [
  '焦慮或緊張',
  '恐懼',
  '心跳急速',
  '呼吸困難',
  '注意力難集中',
  '強迫思想',
  '強迫行為',
  '過份擔心身患病',
];

export const OTHER_SYSMTOMS = ['記憶力下降', '判斷力減弱', '其他'];

export const SYMPTOM_FREQUENCY = [
  '很少出現',
  '有時出現',
  '有一半時間出現',
  '大部份時間出現',
  '非常頻密出現',
];

export const SYMPTIOM_EFFECT = [
  '少許影響',
  '有一定程度影響',
  '中等程度影響',
  '頗大程度影響',
  '嚴重影響',
];

export const SYMPTOM_CAUSE_PERSONAL_FACTOR = [
  '成長適應',
  '環境適應',
  '性格',
  '健康',
  '學習',
  '經濟',
  '其他',
];

export const SYMPTOM_CAUSE_RELATION_FACTOR = [
  '伴侶',
  '兄弟姊妹',
  '朋友',
  '同事',
  '子女',
  '父母',
  '其他',
];

export const SYMPTOM_CAUSE_ENVIRONMENT_FACTOR = [
  '工作',
  '居住',
  '社交康樂',
  '社會事件',
  '疫情',
  '政治',
  '其他',
];

export const MAIN_ISSUE_CATEGORY = [
  '情緒困擾',
  '抑鬱症徵狀',
  '焦慮症徵狀',
  '精神分裂症徵狀',
  '躁狂抑鬱症徵狀',
  '性格異常徵狀',
  '認知障礙徵狀',
  '過度活躍／專注力失調／學習障礙徵狀',
  '智力問題',
  '其他問題徵狀',
];

export const CONSULTANT_SERVICE = [
  '情感疏導',
  '意見提供',
  '資源／資料介紹',
  '輔導服務',
  '其他',
];

export const PROVIDED_REFERER_SERVICE = ['有', '沒有'];

export const USER_EMPLOYMENT_STATUS = [
  '在學',
  '在職',
  '待業',
  '家庭主婦',
  '退休',
  '其他',
];

export const CONSULTATION_DURATION = [
  '0-15分鐘',
  '16-30分鐘',
  '31-45分鐘',
  '46-60分鐘',
  '61分鐘或以上',
];

export const WEEK_OF_DATE = [
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
  '星期日',
];

export const CONSULTATION_SESSION = ['2:00pm-6:00pm', '6:00pm-10:00pm'];

export type SurveyFormItem = {
  typeOfChat: string;
  typeOfChatOther: string;
  weekOfDay: string;
  consultationSession: string;
  isFirstTime: string;
  consultingPerson: string;
  othersPersonEffect: string;
  isHimselfUnderMedical: string;
  previousMedicalDate: string;
  medicalNature: string;
  diagnosisResult: string;
  diagnosisResultOther: string;
  underMedicalSocialWorker: string;
  underMedicalNurse: string;
  underMedicalSocialService: string;
  underOtherSocialService: string;
  underOtherSocialServiceOther: string;
  symptomFrequency: string;
  symptomDuration: string;
  symptomEffect: string;
  symptomCausePersonalFactor: string;
  symptomCausePersonalFactorOther: string;
  symptomCauseRelationFactor: string;
  symptomCauseRelationFactorOther: string;
  symptomCauseEnvironmentFactor: string;
  symptomCauseEnvironmentFactorOther: string;
  symptomCauseOtherFactor: string;
  mainIssueCategory: string;
  mainIssueCategoryOther: string;
  consultantService: string;
  consultantServiceOther: string;
  providedRefererService: string;
  providedRefererServiceOther: string;
  userName: string;
  userEmploymentStatus: string;
  userEmploymentStatusOther: string;
  consultationDuration: string;
  remarks: string;
  schizophrenia: string[];
  depression: string[];
  mania: string[];
  anxiety: string[];
  otherSystoms: string[];
  otherSystomsOther: string;
};

export const SURVEY_FORM_ITEM = {
  typeOfChat: '',
  typeOfChatOther: '',
  weekOfDay: '',
  consultationSession: '',
  isFirstTime: '',
  consultingPerson: '',
  othersPersonEffect: '',
  isHimselfUnderMedical: '',
  previousMedicalDate: '',
  medicalNature: '',
  diagnosisResult: '',
  diagnosisResultOther: '',
  underMedicalSocialWorker: '',
  underMedicalNurse: '',
  underMedicalSocialService: '',
  underOtherSocialService: '',
  underOtherSocialServiceOther: '',
  symptomFrequency: '',
  symptomDuration: '',
  symptomEffect: '',
  symptomCausePersonalFactor: '',
  symptomCausePersonalFactorOther: '',
  symptomCauseRelationFactor: '',
  symptomCauseRelationFactorOther: '',
  symptomCauseEnvironmentFactor: '',
  symptomCauseEnvironmentFactorOther: '',
  symptomCauseOtherFactor: '',
  mainIssueCategory: '',
  mainIssueCategoryOther: '',
  consultantService: '',
  consultantServiceOther: '',
  providedRefererService: '',
  providedRefererServiceOther: '',
  userName: '',
  userEmploymentStatus: '',
  userEmploymentStatusOther: '',
  consultationDuration: '',
  remarks: '',
  schizophrenia: [],
  depression: [],
  mania: [],
  anxiety: [],
  otherSystoms: [],
  otherSystomsOther: '',
};

export const SurveySchema = Yup.object().shape({
  typeOfChat: Yup.string().required('必填'),
  weekOfDay: Yup.string().required('必填'),
  consultationSession: Yup.string().required('必填'),
});
