import Breadcrumbs from 'app/components/Common/Breadcrumb';
import { Pagination, Table } from 'app/components/Table';
import { APP_NAME } from 'app/config';
import { Quiz, QUIZ_TYPE_OPTIONS } from 'app/models/';
import { GetQuizListParams, getQuizList } from 'app/services/QuizService';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import QuizListColumns from './QuizListColumns';
import { useTableComponentForListPage } from 'app/hooks';
import TableCount from 'app/components/Common/TableCount';
import { SelectFilter } from 'app/components/Filter';

export const QuizListPage = () => {
  const { dataList: quizList, count, page } = useTableComponentForListPage<
    GetQuizListParams,
    Quiz
  >(getQuizList, ['type', 'asc', 'desc']);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>測驗記錄列表 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="測驗記錄" breadcrumbItem="測驗記錄列表" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <>
                    <Row>
                      <Col lg={12}>
                        <SelectFilter
                          name="type"
                          label="測驗類型"
                          placeholder="測驗類型"
                          options={QUIZ_TYPE_OPTIONS}
                          isClearable
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col
                        sm={12}
                        className="d-flex align-self-end justify-content-end"
                      >
                        <TableCount count={count} title="測驗記錄總數" />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={QuizListColumns(page)}
                            keyField="quizId"
                            data={quizList || []}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination count={count} current={page} />
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
