export const API_URL =
  process.env.REACT_APP_API_URL || 'https://mhahk-app-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const RAW_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 20;
export const TOAST_TIME = 5000;
export const APP_NAME = 'MHAHK';
export const IMAGE_SIZE = 1024 * 10000;
export const PDF_SIZE = 1024 * 100000;
export const CHATROOM_FETCH_LIMIT = 20;

// firebase
export const FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY ||
  'AIzaSyAoJjhG_JnHTExzSOs72lp1joltELeInvY';
export const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '';
export const FIREBASE_ID = process.env.REACT_APP_FIREBASE_ID || 'mhahk-app-dev';
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_STORAGE || 'mhahk-app-dev.appspot.com';
export const FIREBASE_APP_ID =
  process.env.REACT_APP_APP_ID ||
  '1:470168186576:android:351b15882d611eaa88f8d5';
