import Axios from '.';
import {
  AdminLoginPayload,
  ListResult,
  Admin,
  AdminPara,
  Filter,
  AdminTeam,
} from '../models';

export interface AdminLoginResponse {
  token: string;
  admin: Admin;
  firebaseEmail: string;
}

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post<AdminLoginResponse>(
    '/admins/passwordLogin',
    para,
  );
  return data;
};

export const adminLogout = async () => {
  const { data } = await Axios.post<string>('/admins/me/logout');
  return data;
};

export interface GetAdminListParams extends Filter {}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admins', {
    params,
  });
  return data;
};

export const createNewAdmin = async (para: AdminPara) => {
  const { data } = await Axios.post<Admin>('/admins', para);
  return data;
};

export const getSingleAdmin = async (adminId: string) => {
  const { data } = await Axios.get<Admin>(`/admins/${adminId}`);
  return data;
};

export const updateAdmin = async (
  adminId: number,
  para: { displayName: string },
) => {
  const { data } = await Axios.patch<null>(`/admins/${adminId}`, para);
  return data;
};

export const deleteAdmin = async (adminId: number) => {
  const { data } = await Axios.delete<string>(`/admins/${adminId}`);
  return data;
};

export const getProfile = async () => {
  const { data } = await Axios.get<Admin>('/admins/me/profile');
  return data;
};

export const resetAdminPassword = async (
  adminId: number,
  para: { password?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admins/${adminId}/resetPassword`,
    para,
  );
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post<any>('/logout');
  return data;
};

export const getAdminTeams = async () => {
  const { data } = await Axios.get<AdminTeam[]>('/admins/teams');
  return data;
};
