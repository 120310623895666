/* eslint-disable prettier/prettier */
import { getImageURL, getPDFURL } from 'app/helpers/CommonHelper';
import { uploadImage, uploadRaw } from 'app/services/CommonService';
import {
  createNewUsefulInfo,
  deleteOneUsefulInfo,
  getOneUsefulInfo,
  updateOneUsefulInfo,
  getUsefulInfoCategoryList,
  CreateNewUsefulInfoParams,
} from 'app/services/UsefulInfoService';
import BraftEditor, { EditorState } from 'braft-editor';
import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { APP_NAME } from 'app/config';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';
import Dialog from 'app/components/Modal/Modal';
import {
  HTMLField,
  InputField,
  ImageField,
  CreatableSelectField,
  PDFField,
} from 'app/components/Form';
import * as Yup from 'yup';
import { UsefulInfoCategory, OptionItems } from 'app/models';

const Schema = Yup.object().shape({
  title: Yup.string()
    .required('必填項目')
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元'),
  categoryName: Yup.string()
    .required('必填項目')
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元'),
  images: Yup.mixed().required('必填項目').nullable(),
  contentType: Yup.string().required('必填項目').nullable(),
  pdf: Yup.mixed()
    .nullable()
    .when('contentType', (contentType: any, field: any) => {
      return contentType === 'pdf' ? field.required('必填項目') : field;
    }),
  content: Yup.mixed()
    .nullable()
    .when('contentType', (contentType: any, field: any) => {
      return contentType === 'html' ? field.required('必填項目') : field;
    }),
  video: Yup.string()

  .nullable()
  .max(500, '不可多於500個字元')
  .matches(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
    '網址格式錯誤',
  ),
});

type FormItem = {
  usefulInfoId?: number;
  title: string;
  categoryName: string;
  contentType: string;
  content: EditorState;
  images?: any;
  video:string
  pdf: any;

};

const FORM_ITEM : FormItem  = {
  title: '',
  categoryName: '',
  contentType: 'html',
  content: '',
  images: null,
  pdf: null,
  video:''
};

interface UsefulInfoFormQuery {
  usefulInfoId: string;
}

export const UsefulInfoFormPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const params = useParams<UsefulInfoFormQuery>();

  const [form, setForm] = useState<FormItem>(FORM_ITEM);
  const [usefulInfoId, setUsefulInfoId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<OptionItems[]>([]);



  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { usefulInfoId: usefulInfoID } = params;
    try {
      const categoryListRes = await getUsefulInfoCategoryList();
      setCategoryList(
        categoryListRes.map((item: UsefulInfoCategory) => ({
          label: item.categoryName,
          value: item.categoryName,
        })),
      );

      if (usefulInfoID) {
        const usefulInfoRes = await getOneUsefulInfo(usefulInfoID);
        const {
          usefulInfoId,
          title,
          categoryName,
          content,
          images,
          pdf,
          video
        } = usefulInfoRes;
  
        setForm({
          usefulInfoId,
          title,
          categoryName,
          contentType: content ? 'html' : 'pdf',
          content: content ? BraftEditor.createEditorState(content) : undefined,
          images: images && images.length > 0 
            ? [
                {
                  preview: getImageURL(images[0], 'original'),
                  image: images[0],
                  name: '現有封面',
                },
              ]
            : null,
          pdf: pdf
            ? [
                {
                  preview: getPDFURL(pdf),
                  name: pdf,
                },
              ]
            : null,
            video: video?video:''
        });
        setUsefulInfoId(usefulInfoId);
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const onSubmit = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    const {
      usefulInfoId,
      title,
      categoryName,
      contentType,
      content,
      images,
      pdf,
      video
    } = values;
    const params: CreateNewUsefulInfoParams = {
      categoryName,
      title: title ? title : '',
      content: content && contentType === 'html' ? content.toHTML() : null,
      images: [],
      pdf: '',
      video: video?video:''
    };
    try {
      if (images && images.length > 0 && images[0].size) {
        const formData = new FormData();
        formData.append('file', images[0]);
        const imageRes = await uploadImage(formData);
        if (imageRes) {
          params.images = [imageRes.fileName];
        }
      } else {
        params.images = [images[0].image];
      }
      if (pdf && contentType === 'pdf') {
        if (pdf.length > 0 && pdf[0].size) {
          const formData = new FormData();
          formData.append('file', pdf[0]);
          const pdfRes = await uploadRaw(formData);
          if (pdfRes) {
            params.pdf = pdfRes.fileName;
          }
        } else {
          params.pdf = pdf[0].image;
        }
      } else {
        params.pdf = null;
      }
      if (!usefulInfoId) {
        await createNewUsefulInfo(params);
        setLoading(false);
        toast.success('新增有用資訊成功。');
        history.push('/usefulInfo');
      } else {
        await updateOneUsefulInfo(usefulInfoId, params);
        setLoading(false);
        toast.success('編輯有用資訊成功');
      }
    } catch (err) {
      setLoading(false);
      toast.error(`${usefulInfoId ? '編輯' : '新增'}有用資訊失敗，請重試。`);
    }
  };

  const deleteConfirm = async () => {
    setLoading(true);
    try {
      await deleteOneUsefulInfo(usefulInfoId!);
      toast.success('成功刪除有用資訊');
      setLoading(false);
      setDeleteVisible(false);
      history.replace('/usefulInfo');
    } catch (err) {
      toast.error('刪除有用資訊失敗，請重試。');
      setLoading(false);
      setDeleteVisible(false);
    }
  };

  const deleteOnClose = () => {
    setDeleteVisible(false);
  };

  const TITLE = `${usefulInfoId ? '編輯' : '新增'}有用資訊`;
  const BreadcrumbsOptions = [
    { title: '有用資訊', path: '/usefulInfo' },
    { title: '有用資訊列表', path: '/usefulInfo' },
    { title: TITLE, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="有用資訊" breadcrumbItems={BreadcrumbsOptions} />
          {!isPreloader ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                      >
                        {({ setFieldValue, values }) => (
                          <>
                            <Form className="form-horizontal">
                              <Row>
                                <Col lg={6}>
                                  <CardTitle>有用資訊資料</CardTitle>
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col lg={12}>
                                  <InputField
                                    name="title"
                                    label="有用資訊標題"
                                    placeholder="有用資訊標題"
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg={12}>
                                  <CreatableSelectField
                                    name="categoryName"
                                    label="資訊類別"
                                    placeholder="資訊類別"
                                    options={categoryList}
                                    onCreateOption={(inputValue: string) => {
                                      setCategoryList([
                                        ...categoryList,
                                        {
                                          label: inputValue,
                                          value: inputValue,
                                        },
                                      ]);
                                      setFieldValue('categoryName', inputValue);
                                    }}
                                    formatCreateLabel={inputValue =>
                                      `新增資訊類別 "${inputValue}"`
                                    }
                                    createOptionPosition="first"
                                    isClearable
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col lg={12}>
                                  <ImageField
                                    name="images"
                                    label="封面圖片  (請上傳寬高比率為4:3的圖片)"
                                    multiple={false}
                                  />
                                </Col>
                              </Row>
                              {values.contentType === 'html' ? (
                                <Row className="mb-4">
                                  <Col lg={12}>
                                    <HTMLField
                                      name="content"
                                      label="有用資訊內容"
                                      placeholder="有用資訊內容"
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <>
                                  {values.contentType === 'pdf' ? (
                                    <Row className="mb-4">
                                      <PDFField
                                        name="pdf"
                                        label="pdf檔案"
                                        multiple={false}
                                      />
                                    </Row>
                                  ) : null}
                                </>
                              )}
                              <div className="mt-3 d-flex flex-row-reverse">
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="ms-1"
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <i className="bx bx-loader-circle bx-spin" />
                                  ) : (
                                    '提交'
                                  )}
                                </Button>
                                {usefulInfoId ? (
                                  <>
                                    <div className="d-flex flex-row-reverse">
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="ms-1"
                                        onClick={() => setDeleteVisible(true)}
                                      >
                                        刪除
                                      </Button>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
        <Dialog
          visible={deleteVisible}
          title="刪除有用資訊"
          onClose={deleteOnClose}
          loading={loading}
          onConfirm={deleteConfirm}
        >
          <p>{`確定要刪除有用資訊（編號: ${usefulInfoId}）?`}</p>
        </Dialog>
      </div>
    </>
  );
};
