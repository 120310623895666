import { TimeStamp } from '.';

export interface Centre extends TimeStamp {
  centreId: number;
  categoryName: string;
  image: string;
  lat: number;
  lng: number;
  rootCategory: string;
  centreName: string;
  address: string;
  webUrl: string;
  phone: string;
  whatsapp: string;
}

export interface CentreCategory {
  categoryName: string;
  rootCategory: string;
  count: number;
  ordering: number;
}

export const ROOT_CATEGORY_OPTIONS = [
  {
    label: '社區人士',
    value: '社區人士',
  },
  {
    label: '復元人士照顧者',
    value: '復元人士照顧者',
  },
  {
    label: '智力障礙人士照顧者',
    value: '智力障礙人士照顧者',
  },
];
