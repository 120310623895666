/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Import root app
import { App } from 'app';

import { store, persistor } from './store';

// Initialize languages
import './locales/i18n';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey:
    process.env.REACT_APP_BUGSNAG_API_KEY || 'a4e61f8daccbddea75dac074b340f9dc',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  appVersion: process.env.REACT_APP_VERSION,
  autoTrackSessions: false,
});

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <React.StrictMode>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}
