import Axios from './';
import {
  ListResult,
  User,
  Filter,
  ChatRoomStatus,
  UpdateSurveyParams,
  SurveyResponse,
  ChatRoomStatusLog,
} from '../models';

export interface GetUserInfoListParams extends Filter {
  q?: string;
  name?: string;
  status?: string;
  desc?: string;
  asc?: string;
  createdAtGte?: string;
  createdAtLte?: string;
}

export const getUserInfoList = async (params: GetUserInfoListParams) => {
  const { data } = await Axios.get<ListResult<User>>('/userInfo/list', {
    params,
  });
  return data;
};

export const getOneUserInfo = async (userId: string | number) => {
  const { data } = await Axios.get<User>(`/userInfo/${userId}`);
  return data;
};
export const getOneUserInfoChatRoom = async (userId: string) => {
  const { data } = await Axios.get<string[]>(`/userInfo/${userId}/rooms`);
  return data;
};

export interface UpdateUserInfoParams {
  remarks: string;
}

export const updateUserInfo = async (
  userId: number,
  params: UpdateUserInfoParams,
) => {
  const { data } = await Axios.patch<User>(`/userInfo/${userId}`, params);
  return data;
};

export const closeChatRoom = async (userId: number) => {
  const { data } = await Axios.post<User>(`/userInfo/${userId}/close`);
  return data;
};

/**
* * set user typing (https://mhahk-app-dev.appicidea.com/doc/#/UserInfo/UserInfoController_setTyping)
+*/

export const updateTypingStatus = async (roomId: string) => {
  const { data } = await Axios.post<any>(`/userInfo/rooms/${roomId}/typing`);
  return data;
};

/**
* * get consultation service status (https://mhahk-app-dev.appicidea.com/doc/#/UserInfo/UserInfoController_getChatRoomStatus)
* * Service is on or close
+*/

export const getConsultationServiceStatus = async () => {
  const { data } = await Axios.get<ChatRoomStatus>(
    `/userInfo/rooms/chatRoomStatus`,
  );
  return data;
};

/**
* * patch consultation service status (https://mhahk-app-dev.appicidea.com/doc/#/UserInfo/UserInfoController_setChatRoomStatus)
* * Service is on or close
+*/

export const setConsultationServiceStatus = async (status: boolean) => {
  const { data } = await Axios.patch<ChatRoomStatus>(
    `/userInfo/rooms/chatRoomStatus`,
    { status: status ? 'Started' : 'Suspended' },
  );
  return data;
};

/**
* * update room is handled by which team (https://mhahk-app-dev.appicidea.com/doc/#/UserInfo/UserInfoController_setRoomHandledBy)
+*/

export const setRoomHandledBy = async (roomId: string) => {
  const { data } = await Axios.post<any>(`/userInfo/rooms/${roomId}/handledBy`);
  return data;
};

/**
* * update survey (https://mhahk-app-dev.appicidea.com/doc/#/UserInfo/UserInfoController_updateOrCreateSurvey)
+*/

export const updateUserSurvey = async (
  userId: string | number,
  params: UpdateSurveyParams,
) => {
  const { data } = await Axios.put<SurveyResponse>(
    `/userInfo/${userId}/survey`,
    params,
  );
  return data;
};

/**
* *get one survey by userid (https://mhahk-app-dev.appicidea.com/doc/#/UserInfo/UserInfoController_getSurvey)
+*/

export const getOneSurveyByUserId = async (userId: string | number) => {
  const { data } = await Axios.get<any>(`/userInfo/${userId}/survey`);
  return data;
};

/**
* * get chatroom status log list (https://mhahk-app-dev.appicidea.com/doc/#/UserInfo/UserInfoController_getRoomStatusLog)
+*/

export interface GetCetChatRoomLogListParams extends Filter {
  desc?: string;
  asc?: string;
}

export const getChatRoomLogList = async (
  params: GetCetChatRoomLogListParams,
) => {
  const { data } = await Axios.get<ListResult<ChatRoomStatusLog>>(
    '/userInfo/rooms/chatRoomStatus/Log',
    {
      params,
    },
  );
  return data;
};
