import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import { Row, Col } from 'reactstrap';

import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (value?: any) => void;
  isClearable?: boolean;
  disabled?: boolean;
  col?: number;
}

const CheckboxField = (props: OtherProps & FieldHookConfig<string[]>) => {
  const [field, meta, helpers] = useField(props);
  const { label, options, onChange, disabled, col } = props;
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Row style={{ margin: 'unset' }}>
        {options.map(option => (
          <Col md={col || 12} key={option.label} className="mb-1">
            <input
              className="form-check-input"
              style={{ padding: 6 }}
              checked={field?.value?.includes(option.value)}
              type="checkbox"
              value={option.value}
              onChange={e => {
                if (onChange) {
                  onChange(e);
                } else {
                  if (field.value.includes(e.target.value)) {
                    helpers.setValue(
                      field.value.filter(i => i !== e.target.value),
                    );
                  } else {
                    helpers.setValue([...field.value, e.target.value]);
                  }
                }
              }}
              disabled={disabled}
            />
            <label className="form-check-label ms-1">{option.label}</label>
          </Col>
        ))}
      </Row>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default CheckboxField;
