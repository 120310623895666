import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';

import user1 from 'app/assets/images/users/avatar.png';
import { ADMIN_ROLES } from 'app/models';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import Dialog from 'app/components/Modal/Modal';
import { updateConsultationServiceStatus } from 'store/Auth';
import { toast } from 'react-toastify';
import { setConsultationServiceStatus } from 'app/services/UserService';

const Offsymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      關閉
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 12,
        color: '#fff',
        paddingRight: 2,
      }}
    >
      {' '}
      開啟
    </div>
  );
};

const ProfileMenu = props => {
  const dispatch = useDispatch();
  const { Auth, consultationServiceIsOpen } = useSelector(
    (rootState: RootState) => rootState.auth,
  );
  const currentRole = Auth?.role;
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState('Admin');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (Auth) {
      setusername(Auth.displayName);
    }
  }, [Auth]);

  const toggleConsultationStatus = async () => {
    setLoading(true);
    try {
      let res = await setConsultationServiceStatus(!consultationServiceIsOpen);
      if (res.status === 'Started') {
        dispatch(updateConsultationServiceStatus(true));
      } else {
        dispatch(updateConsultationServiceStatus(false));
      }
      setLoading(false);
      setIsVisible(false);
    } catch (err) {
      setLoading(false);
      toast.error('更新失敗，請重試。');
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          {/*  */}

          <span className="d-none d-lg-inline-block ms-2 me-1">
            {username}
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" style={{ minWidth: 250 }}>
          <div className="d-flex flex-column py-1 px-3">
            <label className="text-muted">身份</label>
            <p className="text-dark font-size-14">
              {ADMIN_ROLES.get(currentRole ? currentRole : '超級管理員')?.label}
            </p>
            <label className="text-muted">團隊</label>
            <p className="text-dark font-size-14">
              {Auth?.team ? Auth.team : '-'}
            </p>
          </div>
          <DropdownItem divider />

          <>
            <div className="d-flex flex-row align-items-center justify-content-between py-1 px-3">
              <label className="text-muted mb-0">線上輔導</label>
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#f8b425"
                onChange={e => {
                  setIsVisible(true);
                }}
                checked={consultationServiceIsOpen}
              />
            </div>
            <DropdownItem divider />
          </>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>登出</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Dialog
        visible={isVisible}
        title="更新輔導服務狀態"
        onClose={() => setIsVisible(false)}
        loading={loading}
        onConfirm={toggleConsultationStatus}
      >
        <p>{`線上輔導服務現時正${
          consultationServiceIsOpen ? '開啟' : '關閉'
        }，確定要${!consultationServiceIsOpen ? '開啟' : '關閉'}`}</p>
      </Dialog>
    </React.Fragment>
  );
};

export default ProfileMenu;
