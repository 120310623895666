import { TimeStamp } from './Common';

export type AdminRoleType = '超級管理員' | '輔導員';

export interface AdminRoleInfo {
  label: string;
  value: string;
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
}

export const ADMIN_ROLES = new Map<AdminRoleType, AdminRoleInfo>([
  [
    '超級管理員',
    { label: '超級管理員', value: '超級管理員', color: 'warning' },
  ],
  ['輔導員', { label: '輔導員', value: '輔導員', color: 'success' }],
]);

export interface AdminLoginPayload {
  loginName: string;
  password: string;
}

export interface Admin extends TimeStamp {
  adminId: number;
  hash: string | null;
  loginName: string;
  displayName: string;
  role: AdminRoleType;
  uid: string;
  team: string | null;
  lastLoginTime: string | null;
  lastLogoutTime: string | null;
}

export interface AdminPara {
  displayName: string;
  loginName?: string;
  password?: string;
}

export interface AdminTeam {
  count: number;
  team: string;
}
