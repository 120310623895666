/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useCallback } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useAdminPermission } from 'store/Permission';

const SidebarContent = props => {
  const { chatRoom, auth } = useSelector((rootState: RootState) => rootState);
  const { unreadChatroomCount } = chatRoom;
  const { consultationServiceIsOpen } = auth;
  const ref = useRef<any>();
  const location = useLocation();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  const { hasPermission } = useAdminPermission();

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">功能列表</li>
            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink
                  to="/news"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-news"></i>
                  <span>最新資訊</span>
                </NavLink>
              </li>
            ) : null}

            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink
                  to="/quizes"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-book-content"></i>
                  <span>測驗記錄</span>
                </NavLink>
              </li>
            ) : null}

            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink
                  to="/centres"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-building"></i>
                  <span>服務中心</span>
                </NavLink>
              </li>
            ) : null}

            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink
                  to="/usefulInfo"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bxs-news"></i>
                  <span>有用資訊</span>
                </NavLink>
              </li>
            ) : null}

            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink className="has-arrow waves-effect" to="/sorting">
                  <i className="bx bx-sort"></i>
                  <span>類別排序</span>
                </NavLink>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <NavLink
                      to="/sorting/newsCategories"
                      activeClassName="active mm-active"
                    >
                      最新資訊類別
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sorting/centreCategories?rootCategory=社區人士"
                      activeClassName="active mm-active"
                    >
                      中心地區
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sorting/usefulInfoCategories"
                      activeClassName="active mm-active"
                    >
                      有用資訊類別
                    </NavLink>
                  </li>
                </ul>
              </li>
            ) : null}

            {hasPermission(['超級管理員', '輔導員']) ? (
              <li className="position-relative">
                <NavLink
                  to={'/consulting'}
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-chat"></i>
                  <span>線上輔導</span>
                  {unreadChatroomCount &&
                  unreadChatroomCount > 0 &&
                  consultationServiceIsOpen ? (
                    <span className="badge bg-warning font-size-11 ms-2">
                      {unreadChatroomCount}
                    </span>
                  ) : null}
                  {!consultationServiceIsOpen ? (
                    <span className="badge bg-secondary font-size-11 ms-2">
                      服務已關閉
                    </span>
                  ) : null}
                </NavLink>
              </li>
            ) : null}

            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink
                  to="/consultingRecord"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-receipt"></i>
                  <span>輔導記錄</span>
                </NavLink>
              </li>
            ) : null}

            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink
                  to="/chat-room-status-log"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-list-ul"></i>
                  <span>線上輔導開關紀錄</span>
                </NavLink>
              </li>
            ) : null}

            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink
                  to="/admins"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-user-circle"></i>
                  <span>管理員</span>
                </NavLink>
              </li>
            ) : null}

            {hasPermission(['超級管理員']) ? (
              <li>
                <NavLink
                  to="/notifications"
                  className="waves-effect"
                  activeClassName="active mm-active"
                >
                  <i className="bx bx-mail-send"></i>
                  <span>推送訊息</span>
                </NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
