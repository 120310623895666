import Breadcrumbs from 'app/components/Common/Breadcrumb';
import { Pagination, Table } from 'app/components/Table';
import { APP_NAME } from 'app/config';
import { UsefulInfo, UsefulInfoCategory } from 'app/models/UsefulInfo';
import {
  GetUsefulInfoListParams,
  getUsefulInfoList,
  getUsefulInfoCategoryList,
} from 'app/services/UsefulInfoService';
import { useState, useCallback, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { SelectFilter } from 'app/components/Filter';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import UsefulInfoListColumns from './UsefulInfoListColumns';
import { OptionItems } from 'app/models';
import { useTableComponentForListPage } from 'app/hooks';
import TableCount from 'app/components/Common/TableCount';
import { Link } from 'react-router-dom';

export const UsefulInfoListPage = () => {
  const [categoryList, setCategoryList] = useState<OptionItems[]>([]);

  const {
    dataList: usefulInfoList,
    count,
    page,
  } = useTableComponentForListPage<GetUsefulInfoListParams, UsefulInfo>(
    getUsefulInfoList,
    ['category', 'asc', 'desc'],
  );

  const getUsefulInfoCategory = useCallback(async () => {
    try {
      const categoryListRes = await getUsefulInfoCategoryList();
      setCategoryList(
        categoryListRes.map((item: UsefulInfoCategory) => ({
          label: item.categoryName,
          value: item.categoryName,
        })),
      );
    } catch (err) {
      console.error(err);
    }
  }, []);
  useEffect(() => {
    getUsefulInfoCategory();
  }, [getUsefulInfoCategory]);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>有用資訊列表 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="有用資訊" breadcrumbItem="有用資訊列表" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <>
                    <Row className="mb-2">
                      <Col lg={8} sm={12}>
                        <SelectFilter
                          name="category"
                          label="資訊類別"
                          placeholder="資訊類別"
                          options={categoryList}
                          isClearable
                        />
                      </Col>
                      <Col
                        lg={4}
                        sm={12}
                        className="d-flex flex-row-reverse align-items-end"
                      >
                        <div>
                          <Link
                            to="/usefulInfo/new"
                            className="btn btn-primary mb-2"
                            role="button"
                            aria-pressed="true"
                          >
                            {` ${'新增有用資訊'}`}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col
                        sm={12}
                        className="d-flex align-self-end justify-content-end"
                      >
                        <TableCount count={count} title="有用資訊總數" />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={UsefulInfoListColumns()}
                            keyField="usefulInfoId"
                            data={usefulInfoList || []}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination count={count} current={page} />
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
