import { Filter, ListResult, UsefulInfo, UsefulInfoCategory } from 'app/models';

import Axios from '.';

/**
 * Get UsefulInfo list (https://mhahk-app-dev.appicidea.com/doc/#/UsefulInfo/UsefulInfoController_listUsefulInfo)
 */

export interface GetUsefulInfoListParams extends Filter {
  category?: string;
  q?: string;
}

export const getUsefulInfoList = async (params: GetUsefulInfoListParams) => {
  const { data } = await Axios.get<ListResult<UsefulInfo>>('/usefulInfo', {
    params,
  });
  return data;
};

/**
 * Create new UsefulInfo (https://mhahk-app-dev.appicidea.com/doc/#/UsefulInfo/UsefulInfoController_createUsefulInfo)
 */

export interface CreateNewUsefulInfoParams {
  title: string;
  categoryName: string;
  content?: string;
  images?: string[];
  video?: string;
  pdf?: string | null;
}

export const createNewUsefulInfo = async (
  params: CreateNewUsefulInfoParams,
) => {
  const { data } = await Axios.post<UsefulInfo>('/usefulInfo', params);
  return data;
};

/**
 * Get one UsefulInfo (https://mhahk-app-dev.appicidea.com/doc/#/UsefulInfo/UsefulInfoController_getUsefulInfoById)
 */

export const getOneUsefulInfo = async (usefulInfoId: string | number) => {
  const { data } = await Axios.get<UsefulInfo>(`/usefulInfo/${usefulInfoId}`);
  return data;
};

/**
 * Update one UsefulInfo (https://mhahk-app-dev.appicidea.com/doc/#/UsefulInfo/UsefulInfoController_updateUsefulInfoById)
 */

export const updateOneUsefulInfo = async (
  usefulInfoId: number,
  params: Partial<CreateNewUsefulInfoParams>,
) => {
  const { data } = await Axios.patch<string>(
    `/usefulInfo/${usefulInfoId}`,
    params,
  );
  return data;
};

/**
 * Delete one UsefulInfo (https://mhahk-app-dev.appicidea.com/doc/#/UsefulInfo/UsefulInfoController_deleteUsefulInfoById)
 */

export const deleteOneUsefulInfo = async (usefulInfoId: number) => {
  const { data } = await Axios.delete<string>(`/usefulInfo/${usefulInfoId}`);
  return data;
};

/**
 * Get UsefulInfo Category list (https://mhahk-app-dev.appicidea.com/doc/#/UsefulInfo/UsefulInfoController_listCategory)
 */

export const getUsefulInfoCategoryList = async () => {
  const { data } = await Axios.get<UsefulInfoCategory[]>(
    '/usefulInfo/category',
  );
  return data;
};

/**
 * Update UsefulInfo Category list (https://mhahk-app-dev.appicidea.com/doc/#/UsefulInfo/UsefulInfoController_updateCategoryOrder)
 */

export interface UpdateNewCategoryListParams {
  categoryName: string;
  ordering: number;
}

export const updateUsefulInfoCategoryList = async (
  params: UpdateNewCategoryListParams[],
) => {
  const { data } = await Axios.put<UsefulInfoCategory[]>(
    '/usefulInfo/category/ordering',
    params,
  );
  return data;
};

/**
 * Send UsefulInfo Notification (xxxxx)
 */

// export interface SendUsefulInfoNotificationParams {
//   title: string;
// }
// export const sendUsefulInfoNotification = async (
//   usefulInfoId: number,
//   params: SendUsefulInfoNotificationParams,
// ) => {
//   const { data } = await Axios.post<string>(
//     `/usefulInfo/${usefulInfoId}/notification`,
//     params,
//   );
//   return data;
// };
