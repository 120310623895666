import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Admin } from 'app/models';
import { adminLogout, getProfile } from 'app/services/AdminService';
import { auth } from 'app/services/FirebaseService';
import { getConsultationServiceStatus } from 'app/services/UserService';
import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';
import { AppThunk } from 'store';

interface AuthState {
  error: string | null;
  success: string | null;
  loginLoading: boolean;
  loginError: string;
  Auth: Admin | null;
  consultationServiceIsOpen: boolean;
}

const initialState = {
  error: '',
  success: 'ok',
  loginLoading: false,
  loginError: '',
  Auth: null,
  consultationServiceIsOpen: false,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loginLoading = true;
      state.loginError = '';
    },
    loginSuccess(state) {
      state.loginLoading = false;
      state.loginError = '';
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    setProfile(state, action: PayloadAction<Admin | null>) {
      state.Auth = action.payload;
    },
    updateConsultationServiceStatus(state, action: PayloadAction<boolean>) {
      state.consultationServiceIsOpen = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFail,
  setProfile,
  updateConsultationServiceStatus,
} = authSlice.actions;
export default authSlice.reducer;

// dispatch actions

export const logoutUser = (history): AppThunk => async dispatch => {
  try {
    await adminLogout();
    dispatch(setProfile(null));
    await auth.signOut();
    history.replace('/login');
  } catch (err) {
    console.log(err);
    toast.warning('請檢查你的網絡。');
  }
};

export const getCurrentProfile = (): AppThunk => async dispatch => {
  try {
    const profile = await getProfile();
    const consultaionServiceStatusRes = await getConsultationServiceStatus();
    if (consultaionServiceStatusRes.status === 'Started') {
      dispatch(updateConsultationServiceStatus(true));
    } else {
      dispatch(updateConsultationServiceStatus(false));
    }
    dispatch(setProfile(profile));
  } catch (err: any) {
    if (err.response.status !== 401) {
      toast.warning('請檢查你的網絡。');
    }
  }
};
