import { getImageURL } from 'app/helpers/CommonHelper';
import { UsefulInfo } from 'app/models/UsefulInfo';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import './usefulInfo.scss';

const UsefulInfoListColumns = () => [
  {
    text: '',
    dataField: 'img',
    formatter: (usefulInfo: UsefulInfo) => (
      <>
        {!(usefulInfo.images && usefulInfo.images.length > 0) ? (
          <div className="avatar-lg">
            <h1 className="avatar-videoUrl rounded-circle bg-light text-secondary">
              <i className="bx bx-usefulInfo " />
            </h1>
          </div>
        ) : (
          <img
            className="avatar-lg rounded-circle "
            src={getImageURL(usefulInfo.images[0])}
            alt="cover"
          />
        )}
      </>
    ),
  },
  {
    text: '編號',
    dataField: 'usefulInfoId',
    sort: false,
    formatter: (usefulInfo: UsefulInfo) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">#{usefulInfo.usefulInfoId}</div>
        </h5>
      </>
    ),
  },
  {
    text: '標題',
    dataField: 'title',
    sort: false,
    formatter: (usefulInfo: UsefulInfo) => (
      <>
        <h5 className="font-size-14 mb-1 d-flex flex-row">
          <div className="text-dark">{usefulInfo.title}</div>
        </h5>
      </>
    ),
  },
  {
    text: '資訊類別',
    dataField: 'categoryName',
    sort: false,
    formatter: (usefulInfo: UsefulInfo) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Badge pill className="badge-soft-primary ms-1 p-2 font-size-12">
            {usefulInfo.categoryName ? usefulInfo.categoryName : '-'}
          </Badge>
        </h5>
      </>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    sort: false,
    formatter: (usefulInfo: UsefulInfo) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {usefulInfo?.createdAt
              ? moment(usefulInfo.createdAt).format('DD-MM-YYYY HH:mm:ss')
              : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (usefulInfo: UsefulInfo) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item ">
          <Link
            to={`/usefulInfo/edit/${usefulInfo.usefulInfoId}`}
            id={'edit' + usefulInfo.usefulInfoId}
          >
            <i className="bx bx-edit-alt" />
            <UncontrolledTooltip
              placement="top"
              target={'edit' + usefulInfo.usefulInfoId}
            >
              編輯資訊
            </UncontrolledTooltip>
          </Link>
        </li>
      </ul>
    ),
  },
];

export default UsefulInfoListColumns;
