import React from 'react';
import clsx from 'clsx';
import { Input } from 'reactstrap';
import { Label } from 'reactstrap';
import { FormGroup } from 'reactstrap';

type InputType = 'checkbox' | 'radio';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
  rows?: number;
  className?: string;
  check?: boolean;
}

const RadioCheckboxField = (props: OtherProps) => {
  const { label, type, horizontal, className, disabled, check } = props;
  return (
    <>
      <FormGroup
        check
        className={clsx(
          horizontal && 'd-inline-block mb-1 mt-1 ms-1 me-1',
          className && className,
        )}
      >
        <Label className={clsx(className)} check>
          <Input
            type={type}
            style={{ height: '1.1em', width: '1.1em' }}
            disabled={disabled}
            checked={check}
          />

          {label}
        </Label>
      </FormGroup>
    </>
  );
};

export default RadioCheckboxField;
