import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { db } from 'app/services/FirebaseService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store';
import {
  FirebaseQuerySnapshot,
  Chatroom,
  FirebaseDocumentSnapshot,
  FirestoreChatroomData,
  FirestoreUserData,
} from 'app/models';
import { CHATROOM_FETCH_LIMIT } from 'app/config';
import { formatChatRoom } from 'app/helpers/ChatRoomHelper';
import { sleep } from 'app/helpers/CommonHelper';
import { useHistory } from 'react-router-dom';
import MESSAGE_SOUND from 'app/assets/audio/message.wav';
import moment from 'moment';

type RoomUserMap = Record<string, FirestoreUserData>;
export interface ChatRoomState {
  chatroomList: Chatroom[];
  refreshing: boolean;
  appending: boolean;
  unreadChatroomCount: number;
  roomIsEnd: boolean;
  chatRoomFilter: string;
  roomUserMap: RoomUserMap;
  roomId: string | null;
}

const initialState = {
  chatroomList: [],
  refreshing: false,
  appending: false,
  unreadChatroomCount: 0,
  roomIsEnd: false,
  chatRoomFilter: '',
  roomUserMap: {},
  roomId: null,
} as ChatRoomState;

const chatRoomSlice = createSlice({
  name: 'chatRoom',
  initialState,
  reducers: {
    refreshListStart(state) {
      state.refreshing = true;
    },
    refreshListSuccess(state, action: PayloadAction<Chatroom[]>) {
      state.chatroomList = action.payload;
      state.refreshing = false;
    },
    refreshListFail(state) {
      state.refreshing = false;
    },
    appendListStart(state) {
      state.appending = true;
    },
    appendList(state, action: PayloadAction<Chatroom[]>) {
      state.chatroomList = [...state.chatroomList, ...action.payload];
    },
    appendListSuccess(state) {
      state.appending = false;
    },
    appendListFail(state) {
      state.appending = false;
    },
    setChatroomList(state, action: PayloadAction<Chatroom[]>) {
      state.chatroomList = action.payload;
      state.refreshing = false;
    },
    setListRefreshing(state, action: PayloadAction<boolean>) {
      state.refreshing = action.payload;
    },
    setUnreadChatroomCount(state, action: PayloadAction<number>) {
      state.unreadChatroomCount = action.payload;
    },
    setRoomIsEnd(state, action: PayloadAction<boolean>) {
      state.roomIsEnd = action.payload;
    },
    updateChatRoomFilter(state, action: PayloadAction<string>) {
      state.chatRoomFilter = action.payload;
    },
    setRoomId(state, action: PayloadAction<string | null>) {
      state.roomId = action.payload;
    },
    resetChatRoom() {
      return initialState;
    },
  },
});

export const {
  refreshListStart,
  refreshListSuccess,
  refreshListFail,
  appendListStart,
  appendList,
  appendListSuccess,
  appendListFail,
  setChatroomList,
  setListRefreshing,
  setUnreadChatroomCount,
  setRoomIsEnd,
  updateChatRoomFilter,
  resetChatRoom,
  setRoomId,
} = chatRoomSlice.actions;

export default chatRoomSlice.reducer;

export const useInitMessageListener = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth, chatRoom } = useSelector((rootState: RootState) => rootState);
  const { Auth } = auth;
  const { chatRoomFilter } = chatRoom;
  const roomUserMap = useRef<RoomUserMap>({});

  useEffect(() => {
    if (Auth) {
      dispatch(setListRefreshing(true));
      const { team, role } = Auth;
      let subscriber: () => void;
      let unreadChatroomSubscriber: () => void;

      // result
      const onResult = (snapshot: FirebaseQuerySnapshot) => {
        dispatch(handleNewChatrooms(snapshot, roomUserMap.current));
      };
      // error
      const onError = (error: Error) => {
        if (error.message.includes('Missing or insufficient permissions')) {
          window.location.href = '/logout';
        }
      };

      let unreadQuery = db
        .collection('rooms')
        .where(`isFilledSurvey`, '==', false);
      if (role === '輔導員') {
        unreadQuery = unreadQuery
          .where('isFilledSurvey', '==', false)
          .where('team', 'in', team ? [team, 'Unassigned'] : ['Unassigned'])
          .where(
            'lastMessage.createdAt',
            '>',
            moment().startOf('day').toDate(),
          );
      }
      unreadChatroomSubscriber = unreadQuery.onSnapshot(
        (snapshot: FirebaseQuerySnapshot) => {
          dispatch(setUnreadChatroomCount(snapshot.size));
        },
        onError,
      );

      if (chatRoomFilter) {
        dispatch(setChatroomList([]));
        let query = db
          .collection('rooms')
          .orderBy('lastMessage.createdAt', 'desc')
          .where(
            'isFilledSurvey',
            '==',
            chatRoomFilter === 'true'
              ? true
              : chatRoomFilter === 'false'
              ? false
              : null,
          )
          .limit(CHATROOM_FETCH_LIMIT);
        if (role === '輔導員') {
          query = query
            .where('isFilledSurvey', '==', false)
            .where('team', 'in', team ? [team, 'Unassigned'] : ['Unassigned'])
            .where(
              'lastMessage.createdAt',
              '>',
              moment().startOf('day').toDate(),
            );
        }
        subscriber = query.onSnapshot(onResult, onError);
      } else {
        let query = db
          .collection('rooms')
          .orderBy('lastMessage.createdAt', 'desc')
          .limit(CHATROOM_FETCH_LIMIT);

        if (role === '輔導員') {
          query = query
            .where('isFilledSurvey', '==', false)
            .where('team', 'in', team ? [team, 'Unassigned'] : ['Unassigned'])
            .where(
              'lastMessage.createdAt',
              '>',
              moment().startOf('day').toDate(),
            );
        }
        subscriber = query.onSnapshot(onResult, onError);
      }
      return () => {
        subscriber();
        unreadChatroomSubscriber();
      };
    }
  }, [dispatch, Auth, chatRoomFilter, history]);
};

export const handleNewChatrooms = (
  querySnapshot: FirebaseQuerySnapshot,
  roomUserMap?: RoomUserMap,
): AppThunk => async (dispatch, getState) => {
  const { chatRoom, auth } = getState();
  const { chatroomList } = chatRoom;
  const { Auth } = auth;
  try {
    const incomingChatrooms = await formatChatroomList(
      querySnapshot,
      roomUserMap,
    );
    if (incomingChatrooms.length && chatroomList.length) {
      if (incomingChatrooms[0].id !== chatroomList[0].id) {
        playSound();
      } else if (incomingChatrooms[0].id === chatroomList[0].id) {
        if (
          incomingChatrooms[0].sender !== Auth!.uid &&
          incomingChatrooms[0].lastMessage !== chatroomList[0].lastMessage
        ) {
          playSound();
        }
      }
    }
    dispatch(setChatroomList(incomingChatrooms));
  } catch (error) {
    console.log(error);
  }
};

const formatChatroomList = async (
  querySnapshot: FirebaseQuerySnapshot,
  roomUserMap?: RoomUserMap,
) => {
  try {
    return convertFirestoreChatroomToChatroom(querySnapshot.docs, roomUserMap);
  } catch (error) {
    console.log(error);
    return [];
  }
};

const convertFirestoreChatroomToChatroom = async (
  docs: FirebaseDocumentSnapshot[],
  roomUserMap?: RoomUserMap,
) => {
  let chatrooms: Chatroom[] = [];
  for (let x = 0; x < docs.length; x += 1) {
    const doc = docs[x];
    const data = doc.data() as FirestoreChatroomData;
    if (data && data.userRef) {
      try {
        if (roomUserMap && roomUserMap[data.userRef]) {
          const chatroom = formatChatRoom(
            doc.id,
            data,
            roomUserMap[data.userRef],
          );
          chatrooms.push(chatroom);
        } else {
          const userDocument = await db
            .collection('users')
            .doc(data.userRef)
            .get();
          const userData = userDocument.data() as FirestoreUserData;
          if (roomUserMap) {
            roomUserMap[data.userRef] = userData;
          }
          if (userData) {
            const chatroom = formatChatRoom(doc.id, data, userData);
            chatrooms.push(chatroom);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  return chatrooms;
};

export const appendChatroomList = (scrollBar): AppThunk => async (
  dispatch,
  getState,
) => {
  try {
    const { chatroomList, chatRoomFilter } = getState().chatRoom;
    const { Auth } = getState().auth;
    if (chatroomList.length === 0) {
      return;
    }
    dispatch(appendListStart());
    const lastChatroom = await db
      .collection('rooms')
      .doc(chatroomList[chatroomList.length - 1].id)
      .get();

    let querySnapshot;

    const currentID = chatroomList[chatroomList.length - 1].id;
    if (chatRoomFilter) {
      querySnapshot = db
        .collection('rooms')
        .orderBy('lastMessage.createdAt', 'desc')
        .where(
          'isFilledSurvey',
          '==',
          chatRoomFilter === 'true'
            ? true
            : chatRoomFilter === 'false'
            ? false
            : null,
        )
        .limit(CHATROOM_FETCH_LIMIT)
        .startAfter(lastChatroom);

      if (Auth?.role === '輔導員') {
        querySnapshot = querySnapshot
          .where('isFilledSurvey', '==', false)
          .where(
            'team',
            'in',
            Auth?.team ? [Auth?.team, 'Unassigned'] : ['Unassigned'],
          )
          .where(
            'lastMessage.createdAt',
            '>',
            moment().startOf('day').toDate(),
          );
      }
      querySnapshot = await querySnapshot.get();
    } else {
      querySnapshot = db
        .collection('rooms')
        .orderBy('lastMessage.createdAt', 'desc')
        .limit(CHATROOM_FETCH_LIMIT)
        .startAfter(lastChatroom);
      if (Auth?.role === '輔導員') {
        querySnapshot = querySnapshot
          .where('isFilledSurvey', '==', false)
          .where(
            'team',
            'in',
            Auth?.team ? [Auth?.team, 'Unassigned'] : ['Unassigned'],
          )
          .where(
            'lastMessage.createdAt',
            '>',
            moment().startOf('day').toDate(),
          );
      }
      querySnapshot = await querySnapshot.get();
    }
    if (querySnapshot.docs.length < CHATROOM_FETCH_LIMIT) {
      dispatch(setRoomIsEnd(true));
    }
    const incomingChatrooms = await formatChatroomList(querySnapshot);
    scrollBar.scrollTop = 0;
    dispatch(appendList(incomingChatrooms));

    if (currentID) {
      const item = document.getElementById(currentID!.toString());
      item?.scrollIntoView(false);
    }
    await sleep(500);
    dispatch(appendListSuccess());
  } catch (error) {
    console.log(error);
    dispatch(appendListFail());
  }
};

const playSound = () => {
  const audio = new Audio(MESSAGE_SOUND);
  audio.volume = 1;
  audio && audio.play();
};
